import React from 'react';
import { Router } from '@gatsbyjs/reach-router';
import { DownloadReport } from '../containers/DownloadReport';

const DownloadReportPath = () => (
	<Router>
		<DownloadReport path="download/:code" />
	</Router>
);

export default DownloadReportPath;
