import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';
import { compose, isNil } from 'ramda';
import { CircularLoader } from '@myci/ui-components';
import { useDispatch, useSelector } from 'react-redux';
import { withMiddleware, withReducers } from 'redux-syringe';
import { reducer as authentication } from '@myci/authentication';
import { ThirdPartySharingDownload } from '@myci/module-products-fo';
import {
	fetchThirdPartySharingInfo,
	getThirdPartySharingInfo,
	selectIsFetchingThirdPartySharingInfo,
	thirdPartySharingMiddleware,
} from '@myci/domain-report';

import PublicLayout from '../layouts/PublicLayout';

const PureDownloadReport = ({ code }) => {
	const dispatch = useDispatch();

	const thirdPartySharingEntity = useSelector(getThirdPartySharingInfo(code));
	const isFetchingThirdPartyReportInfo = useSelector(selectIsFetchingThirdPartySharingInfo);

	useEffect(() => {
		if (isNil(thirdPartySharingEntity)) {
			dispatch(fetchThirdPartySharingInfo(code));
		}
	}, [code, thirdPartySharingEntity, fetchThirdPartySharingInfo, getThirdPartySharingInfo]);

	return (
		<PublicLayout header>
			{isFetchingThirdPartyReportInfo || isNil(thirdPartySharingEntity) ? (
				<CircularLoader />
			) : (
				<ThirdPartySharingDownload reportInfo={thirdPartySharingEntity} code={code} />
			)}
		</PublicLayout>
	);
};

PureDownloadReport.propTypes = {
	code: PropTypes.string,
};

export const DownloadReport = compose(
	withMiddleware({ thirdPartySharingMiddleware }, { isGlobal: true }),
	withReducers({ authentication }, { isGlobal: true }),
	memo
)(PureDownloadReport);
